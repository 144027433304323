import { Box } from 'components/Box/Box';
import { VideoThumbnail } from 'components/VideoThumbnail/VideoThumbnail';
import {
  CmsApiSplitBlockAppearance,
  CmsApiSplitBlockVideo,
} from 'modules/cms/api/types/blocks/CmsApiSplitContentBlock';
import { gutterWidth } from 'theme/theme';
import { showModal } from 'zustand-stores/modalStore';

import {
  CmsSplitContentVideoContentButton,
  CmsSplitContentVideoContentPlayButtonHolder,
} from './CmsSplitContentVideoContent.styled';

type Props = {
  appearance: CmsApiSplitBlockAppearance;
  blockId: string;
  index: number;
  preview: boolean;
  section: CmsApiSplitBlockVideo;
  desktopOnly?: boolean;
};

export function CmsSplitContentVideoContent({
  appearance,
  blockId,
  index,
  preview,
  section,
  desktopOnly,
}: Props) {
  const overlapping = appearance === 'OVERLAPPING';
  const fullWidth = appearance === 'FULL_WIDTH';

  const openVideo = () => {
    showModal('MEDIA_GALLERY', {
      items: [
        // @ts-expect-error TODO: fix types
        { type: 'VIDEO', videoUrl: section.data.url },
      ],
      itemIndex: 0,
      hideCaptionContainer: true,
    });
  };

  return (
    <Box
      data-qa-id={`split-content-section-${index}`}
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Position | ... Remove this comment to see the full error message
      position={[undefined, null, !fullWidth ? 'relative' : undefined]}
      width={[1, null, 1 / 2]}
      px={[gutterWidth / 4, null, gutterWidth / 2]}
      py={desktopOnly ? undefined : [gutterWidth / 4, null, gutterWidth / 2]}
      key={blockId}
    >
      <Box
        // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Position | ... Remove this comment to see the full error message
        position={[null, null, fullWidth && !preview ? 'absolute' : undefined]}
        left={[null, null, '50%']}
        top={[null, null, '50%']}
        transform={[
          null,
          null,
          fullWidth && !preview ? 'translate(-50%, -50%)' : null,
        ]}
        height={[
          '100%',
          null,
          overlapping || fullWidth
            ? `calc(100% + ${overlapping ? gutterWidth * 2 : gutterWidth}px)`
            : '100%',
        ]}
        // @ts-expect-error TS(2322): Type 'false | (number | null)[]' is not assignable... Remove this comment to see the full error message
        mt={overlapping && [0, null, -gutterWidth]}
        width={
          overlapping
            ? [
                1,
                null,
                fullWidth ? '100vw' : `calc(100% + ${gutterWidth * 3}px)`,
              ]
            : 1
        }
        ml={[0, null, overlapping && index === 1 ? -gutterWidth * 3 : null]}
      >
        <CmsSplitContentVideoContentButton
          type="button"
          onClick={openVideo}
          aria-label={getText('Open video')}
          $fullWidth={fullWidth}
        >
          <VideoThumbnail
            src={
              section.data.image
                ? { type: 'filestack', data: section.data.image }
                : { type: 'video-thumbnail', videoUrl: section.data.url }
            }
            size={{ type: 'expand' }}
            eagerLoad={index < 2}
            playIcon={{
              size: 'large',
              render: (node) =>
                fullWidth ? (
                  <CmsSplitContentVideoContentPlayButtonHolder $index={index}>
                    {node}
                  </CmsSplitContentVideoContentPlayButtonHolder>
                ) : (
                  node
                ),
            }}
            qaId="split-block-video-image"
          />
        </CmsSplitContentVideoContentButton>
      </Box>
    </Box>
  );
}
