import styled, { css } from 'styled-components';

import {
  thumbnailPlayBackgroundVar,
  thumbnailPlayColorVar,
} from 'components/VideoThumbnail/videoThumbnailCssVars';
import { colors } from 'theme/theme';

const styles = css`
  position: relative;
  background: ${colors.lightContentGrey};
  user-select: none;

  ${thumbnailPlayBackgroundVar}: rgb(0 0 0 / 30%);
  ${thumbnailPlayColorVar}: ${colors.white};

  &:hover {
    ${thumbnailPlayBackgroundVar}: ${colors.white};
    ${thumbnailPlayColorVar}: ${colors.brandBlue};
  }

  > * {
    pointer-events: none;
  }
`;

export const VideoThumbnailDivHolder = styled.div`
  ${styles};
`;

export const VideoThumbnailButtonHolder = styled.button`
  ${styles};
  cursor: pointer;
  appearance: none;
  border: none;
  padding: 0;
  margin: 0;
`;
