type Props = {
  videoId: string;
  resolution: 'max' | 'default';
};

export function getYoutubeThumbnail({ videoId, resolution }: Props): string {
  if (resolution === 'max') {
    return `https://i.ytimg.com/vi_webp/${videoId}/maxresdefault.webp`;
  }

  return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
}
