import { MouseEventHandler, ReactNode, useMemo } from 'react';

import { FilestackImage } from 'components/FilestackImage/FilestackImage';
import { Icon } from 'components/Icon/Icon';
import { VideoSize } from 'types/Video/VideoSize';

import { VideoThumbnailButtonOrDiv } from './ButtonOrDiv/VideoThumbnailButtonOrDiv';
import {
  VideoThumbnailImage,
  VideoThumbnailPlayIconHolder,
} from './VideoThumbnail.styled';
import { VideoThumbnailFromVideoUrl } from './VideoThumbnailFromVideoUrl';
import { VideoThumbnailSource } from './types/VideoThumbnailSource';

type Props = {
  src: VideoThumbnailSource;
  size: VideoSize;
  eagerLoad?: boolean;
  playIcon?: {
    size: 'small' | 'large';
    /**
     * Used to add a wrapper around the play button
     */
    render?: (node: ReactNode) => ReactNode;
  };
  onClick?: MouseEventHandler<HTMLButtonElement>;
  qaId?: string;
};

export function VideoThumbnail({
  src,
  size,
  eagerLoad,
  playIcon,
  onClick,
  qaId,
}: Props) {
  const playIconNode = useMemo(() => {
    if (!playIcon) return null;

    const node = (
      <VideoThumbnailPlayIconHolder $size={playIcon.size}>
        <Icon name="play" size={playIcon.size === 'small' ? 32 : 64} />
      </VideoThumbnailPlayIconHolder>
    );

    return playIcon.render ? playIcon.render(node) : node;
  }, [playIcon]);

  const image = useMemo(() => {
    switch (src.type) {
      case 'image-url':
        return (
          <VideoThumbnailImage
            src={src.imageUrl}
            alt="video thumbnail"
            loading={eagerLoad ? undefined : 'lazy'}
            data-qa-id={qaId}
          />
        );
      case 'video-thumbnail':
        return (
          <VideoThumbnailFromVideoUrl
            videoUrl={src.videoUrl}
            eagerLoad={Boolean(eagerLoad)}
            qaId={qaId}
          />
        );
      case 'filestack':
        return (
          <FilestackImage
            data={src.data}
            width={1920}
            eagerLoad={eagerLoad}
            mobileAspectRatio={{ width: 414, height: 470 }}
            expandSize
            objectFit="cover"
          />
        );
    }
  }, [eagerLoad, qaId, src]);

  return (
    <VideoThumbnailButtonOrDiv size={size} onClick={onClick}>
      {image}

      {playIconNode}
    </VideoThumbnailButtonOrDiv>
  );
}
