import { useMemo } from 'react';

import { VideoSize } from 'types/Video/VideoSize';

export function useVideoSize(size: VideoSize) {
  return useMemo(() => {
    switch (size.type) {
      case 'fixed':
        return { width: size.width, height: size.height };
      case 'expand':
        return { width: '100%', height: '100%' };
    }
  }, [size]);
}
