import { MouseEventHandler, ReactNode } from 'react';

import { VideoSize } from 'types/Video/VideoSize';
import { useVideoSize } from 'utils/video/useVideoSize';

import {
  VideoThumbnailButtonHolder,
  VideoThumbnailDivHolder,
} from './VideoThumbnailButtonOrDiv.styled';

type Props = {
  children: ReactNode;
  size: VideoSize;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export function VideoThumbnailButtonOrDiv({ children, size, onClick }: Props) {
  const { width, height } = useVideoSize(size);

  if (onClick) {
    return (
      <VideoThumbnailButtonHolder
        type="button"
        onClick={onClick}
        style={{ width, height }}
      >
        {children}
      </VideoThumbnailButtonHolder>
    );
  }

  return (
    <VideoThumbnailDivHolder style={{ width, height }}>
      {children}
    </VideoThumbnailDivHolder>
  );
}
