import styled from 'styled-components';

import { cssBreakpoints } from 'theme/theme';

export const CmsSplitContentVideoContentButton = styled.button<{
  $fullWidth: boolean;
}>`
  appearance: none;

  @media all and (max-width: ${cssBreakpoints.smDown}) {
    aspect-ratio: 649 / 370;
    border-radius: 24px;
    overflow: hidden;
  }

  @media all and (min-width: ${cssBreakpoints.smUp}) {
    width: 100%;
    height: 100%;
    border-radius: ${(props) => (props.$fullWidth ? undefined : '24px')};
    overflow: ${(props) => (props.$fullWidth ? undefined : 'hidden')};
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) and (max-width: ${cssBreakpoints.lgDown}) {
    min-height: 25vw;
  }

  @media all and (min-width: ${cssBreakpoints.lgUp}) {
    min-height: ${(540 * 9) / 16};
  }
`;

export const CmsSplitContentVideoContentPlayButtonHolder = styled.div<{
  $index: number;
}>`
  position: absolute;
  top: 50%;

  @media all and (max-width: ${cssBreakpoints.smDown}) {
    left: 50%;
  }

  @media all and (min-width: ${cssBreakpoints.smUp}) {
    left: ${(props) => (props.$index === 0 ? '25%' : '75%')};
  }
`;
