import { VideoData } from 'types/Video/VideoData';

import { getVideoSourceTypeFromUrl } from './getVideoSourceTypeFromUrl';
import { getVimeoVideoId } from './vimeo/getVimeoVideoId';
import { getYoutubeVideoId } from './youtube/getYoutubeVideoId';

type Props = {
  url: string;
};

export function getVideoData({ url }: Props): VideoData | undefined {
  const type = getVideoSourceTypeFromUrl(url);

  switch (type) {
    case 'raw': {
      return { type: 'raw', url };
    }
    case 'youtube': {
      const videoId = getYoutubeVideoId({ url });
      if (!videoId) return undefined;

      return { type, videoId };
    }
    case 'vimeo': {
      const videoId = getVimeoVideoId({ url });
      if (!videoId) return undefined;

      return { type, videoId };
    }
  }
}
