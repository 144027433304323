import { useCallback, useEffect, useState } from 'react';

import { VideoData } from 'types/Video/VideoData';

import { getVimeoThumbnail } from './helpers/getVimeoThumbnail';
import { getYoutubeThumbnail } from './helpers/getYoutubeThumbnail';

type Props = {
  videoData: VideoData | undefined;
};

export function useVideoThumbnailUrl({ videoData }: Props):
  | {
      defaultResolutionUrl: string | undefined;
      highResolutionUrl: string | undefined;
    }
  | undefined {
  const [asyncThumbnailSrcs, setAsyncThumbnailSrcs] = useState<{
    defaultResolutionUrl: string | undefined;
    highResolutionUrl: string | undefined;
  }>();

  const type = videoData?.type;
  const vimeoVideoId =
    videoData?.type === 'vimeo' ? videoData.videoId : undefined;

  const loadAsyncThumbnail = useCallback(async () => {
    if (type === 'vimeo' && vimeoVideoId) {
      const defaultResolutionThumbnailUrl = await getVimeoThumbnail({
        videoId: vimeoVideoId,
        width: 480,
        height: 360,
      });

      const highResolutionThumbnailUrl = await getVimeoThumbnail({
        videoId: vimeoVideoId,
        width: 920,
        height: 720,
      });

      setAsyncThumbnailSrcs({
        defaultResolutionUrl: defaultResolutionThumbnailUrl,
        highResolutionUrl: highResolutionThumbnailUrl,
      });
    }
  }, [type, vimeoVideoId]);

  useEffect(() => {
    loadAsyncThumbnail();
  }, [loadAsyncThumbnail, type]);

  if (videoData?.type === 'youtube') {
    return {
      defaultResolutionUrl: getYoutubeThumbnail({
        videoId: videoData.videoId,
        resolution: 'default',
      }),
      highResolutionUrl: getYoutubeThumbnail({
        videoId: videoData.videoId,
        resolution: 'max',
      }),
    };
  }

  return asyncThumbnailSrcs;
}
