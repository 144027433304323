import { TypedString } from 'types/TypedString';

const vimeoVideoIdPattern = /(vimeo\.com\/video\/|vimeo\.com\/)([0-9]+)/;

type Props = {
  url: string;
};

export function getVimeoVideoId({
  url,
}: Props): TypedString<'vimeo-video-id'> | undefined {
  return url.match(vimeoVideoIdPattern)?.[2];
}
