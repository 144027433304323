import { isVimeoVideoUrl } from './vimeo/isVimeoVideoUrl';
import { isYoutubeVideoUrl } from './youtube/isYoutubeVideoUrl';

export function getVideoSourceTypeFromUrl(
  url: string,
): 'youtube' | 'vimeo' | 'raw' {
  if (isVimeoVideoUrl(url)) return 'vimeo';
  if (isYoutubeVideoUrl(url)) return 'youtube';

  return 'raw';
}
