import { TypedString } from 'types/TypedString';

type Props = {
  videoId: TypedString<'vimeo-video-id'>;
  width: number;
  height: number;
};

export async function getVimeoThumbnail({
  videoId,
  width,
  height,
}: Props): Promise<string | undefined> {
  const response = await fetch(
    `https://vimeo.com/api/oembed.json?url=${encodeURI(
      `https://vimeo.com/${videoId}`,
    )}&width=${width}&height=${height}`,
  );

  const text = await response.text();
  return JSON.parse(text).thumbnail_url;
}
