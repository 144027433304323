import styled from 'styled-components';

import { colors, transition } from 'theme/theme';

import {
  thumbnailPlayBackgroundVar,
  thumbnailPlayColorVar,
} from './videoThumbnailCssVars';

export const VideoThumbnailPlayIconHolder = styled.div<{
  $size: 'small' | 'large';
}>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  width: ${(props) => (props.$size === 'small' ? 60 : 120)}px;
  height: ${(props) => (props.$size === 'small' ? 60 : 120)}px;
  background: var(${thumbnailPlayBackgroundVar});
  color: var(${thumbnailPlayColorVar});
  border: 1px solid ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ${transition};
`;

export const VideoThumbnailImage = styled.img`
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
