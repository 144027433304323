import { TypedString } from 'types/TypedString';

const youtubeVideoIdPattern =
  /(youtu\.be\/|\.com\/v\/|.com\/watch\?v=)([\w-]+)/;

type Props = {
  url: string;
};

export function getYoutubeVideoId({
  url,
}: Props): TypedString<'youtube-video-id'> | undefined {
  return url.match(youtubeVideoIdPattern)?.[2];
}
