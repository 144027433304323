import { SyntheticEvent, useCallback, useMemo, useState } from 'react';

import { getVideoData } from 'utils/video/getVideoData';

import { VideoThumbnailImage } from './VideoThumbnail.styled';
import { useVideoThumbnailUrl } from './useVideoThumbnailUrl';

type Props = {
  videoUrl: string;
  eagerLoad: boolean;
  qaId: string | undefined;
};

export function VideoThumbnailFromVideoUrl({
  videoUrl,
  eagerLoad,
  qaId,
}: Props) {
  const [highResolutionFailed, setHighResolutionFailed] = useState(false);
  const videoData = useMemo(() => getVideoData({ url: videoUrl }), [videoUrl]);

  const videoThumbnailUrls = useVideoThumbnailUrl({ videoData });
  const highResolutionSrc = videoThumbnailUrls?.highResolutionUrl;

  const resolvedSrc = useMemo(() => {
    if (!highResolutionFailed && highResolutionSrc) return highResolutionSrc;

    return videoThumbnailUrls?.defaultResolutionUrl;
  }, [
    highResolutionFailed,
    highResolutionSrc,
    videoThumbnailUrls?.defaultResolutionUrl,
  ]);

  const onImageLoaded = useCallback(
    (event: SyntheticEvent<HTMLImageElement>) => {
      if (highResolutionFailed || !highResolutionSrc) return;

      const img = event.target as HTMLImageElement;
      const { naturalWidth, naturalHeight } = img;

      /**
       * Some old YouTube videos don't have high quality thumbnails.
       *
       * When that happens YouTube still returns an image and `onerror` is never
       * called.
       */
      const isYoutube404Fallback =
        naturalWidth === 120 &&
        naturalHeight === 90 &&
        highResolutionSrc.startsWith('https://i.ytimg.com/vi_webp/');

      if (isYoutube404Fallback) setHighResolutionFailed(true);
    },
    [highResolutionSrc, highResolutionFailed],
  );

  return (
    <VideoThumbnailImage
      src={resolvedSrc}
      alt="video thumbnail"
      loading={eagerLoad ? undefined : 'lazy'}
      data-qa-id={qaId}
      onLoad={onImageLoaded}
    />
  );
}
